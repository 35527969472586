<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <template v-if="isUser && isVerified">
        <div class="form-group">
          <label> 1.Посадова особа </label>
          <v-select
            id="person-to-schedule"
            class="mb-4"
            v-model="selected_worker"
            :options="workersOptions"
            label="text"
            placeholder="Оберіть, до кого записатись на прийом"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              Нічого не знайдено
            </template>
            <template #option="{ text, department }">
              <div class="position">
                <strong>{{ text }}</strong>
              </div>
              <span class="department">
                {{ getDepartment(department) }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="form-group" v-show="selected_worker != null">
          <label> 2.Дата </label>
          <v-select
            id="select-date"
            class="mb-4"
            v-model="selected_date"
            :options="available_days_options"
            :reduce="(text) => text.value"
            label="text"
            placeholder="Оберіть дату"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              Нічого не знайдено
            </template>
          </v-select>
        </div>
        <div class="form-group" v-show="all_time_slots.length">
          <label> 3.Оберіть зручний час </label>
          <div class="time-grid mb-4 mt-3">
            <div
              class="time-grid__item"
              :class="{ selected: t.time == selected_time, busy: t.busy }"
              v-for="t in all_time_slots"
              :key="t.time"
              @click="selectTime(t)"
            >
              {{ $moment(selected_date + ", " + t.time).format("HH:mm") }}
            </div>
          </div>
        </div>
        <div
          v-show="selected_date != null && selected_time != null"
          class="appointment__footer"
        >
          <hr class="mt-2" />
          <div class="w-100">
            <b-alert variant="danger" :show="has_appointment_today">
              У Вас вже є запис до цього посадовця на цей день. Якщо Ви хочете
              змінити час, то скасуйте спочатку попередній запис в
              <router-link :to="{ name: 'appointments' }"
                >Мої записи на прийом</router-link
              >
            </b-alert>
          </div>
          <div
            class="
              cta
              pt-2
              d-flex
              align-items-center
              justify-content-end
              flex-wrap flex-md-nowrap
            "
          >
            <div class="info mr-1 text-end">
              <div class="info-date text-end">
                <div class="date" v-if="selected_date">
                  <strong>Дата: </strong
                  >{{ $moment(selected_date).format("dddd DD MMMM YYYY") }}
                </div>
                <div class="time" v-if="selected_time">
                  <strong>Час: </strong>
                  {{
                    $moment(selected_date + ", " + selected_time).format(
                      "HH:mm"
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="info-cta d-block">
              <b-button
                :disabled="button_disabled"
                @click="openConfirmPopup"
                variant="primary"
                class="d-block w-100 mt-2 mt-md-0"
              >
                Створити запис
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <needsVerirication class="w-100" v-if="isUser && !isVerified" />
        <needsAuth class="w-100" v-if="!isUser" />
      </template>
    </b-overlay>

    <b-modal hide-footer hide-header centered v-model="showConfirmPopup">
      <b-overlay
        :show="creatingLoading"
        variant="white"
        rounded="sm"
        spinner-variant="warning"
      >
        <div class="text-center modal-inner">
          <h5 class="mt-3 mb-4">Створити запис до</h5>
          <h3 class="mb-1" v-if="selected_worker">
            {{ selected_worker.text }}
          </h3>
          <p v-if="selected_worker">
            {{ getDepartment(selected_worker.department) }}
          </p>
          <div class="date" v-if="selected_date">
            <strong class="d-block">Дата:</strong>
            {{ $moment(selected_date).format("dddd DD MMMM YYYY") }}
          </div>
          <div class="time pt-3" v-if="selected_time">
            <strong class="d-block">Час:</strong>
            {{ $moment(selected_date + ", " + selected_time).format("HH:mm") }}
          </div>
          <div class="cta pt-4">
            <b-button
              @click="confirmAppointment"
              class="d-block w-100"
              variant="primary"
            >
              Підтвердити запис
            </b-button>
            <b-button
              @click="showConfirmPopup = false"
              class="d-block w-100 mt-2"
              variant="outline-primary"
            >
              Скасувати
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import needsAuth from "@/components/need-auth-in-service.vue";
import needsVerirication from "@/components/need-verification-in-service.vue";
export default {
  components: {
    vSelect,
    needsAuth,
    needsVerirication,
  },
  data() {
    return {
      isLoading: false,
      creatingLoading: false,
      selected_worker: null,
      selected_date: null,
      selected_time: null,
      showConfirmPopup: false,
      general_hours: {
        start: "08:00",
        end: "18:00",
      },
      workers: [],
      all_days: [],
      day_appointments: [],
    };
  },
  watch: {
    selected_worker: {
      handler(newVal) {
        this.selected_date = null;
        if (newVal.department != this.$route.query.department) {
          this.$router.push({ query: { department: newVal.department } });
        }
      },
    },
    selected_date: {
      handler(newVal) {
        if (newVal != null) {
          this.selected_time = null;
          this.getAppointmentsForSelectedDate();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userData: "getUserData",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),

    button_disabled() {
      let disabled = false;
      if (this.has_appointment_today) {
        disabled = true;
      }
      if (!this.selected_time) {
        disabled = true;
      }

      return disabled;
    },
    has_appointment_today() {
      let has = false;
      let date = this.selected_date;
      let found = this.day_appointments.find((el) => {
        return (
          el.date.toDate().toString() ==
            this.$moment(date).toDate().toString() && !el.canceled
        );
      });
      if (found) {
        has = true;
      }
      return has;
    },
    all_time_slots() {
      let times = [];
      if (this.selected_date) {
        let start = this.$moment(this.selected_date);
        start.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        let end = this.$moment(this.selected_date);
        end.set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
        let interval = start;
        while (interval < end) {
          times.push({
            time: interval.format("HH:mm:ss"),
            busy: this.checkIfTimeBusy(interval.format("HH:mm:ss")),
          });
          interval = interval.add(15, "minutes");
        }
      }
      return times;
    },
    selected_day_schedule() {
      let schedule = {};
      let day = this.$moment(this.selected_date + ", 00:00:00")
        .toDate()
        .getDay();
      if (isNaN(day) == false) {
        schedule = this.current_scedule.find((el) => {
          return el.day == day;
        });
      }
      return schedule;
    },
    current_scedule() {
      let schedule = [];
      if (this.selected_worker) {
        schedule = this.selected_worker.schedule;
      }
      return schedule;
    },
    available_worker_days() {
      let days = [];
      if (this.all_days && this.selected_worker && this.current_scedule) {
        this.all_days.forEach((el) => {
          let date = this.$moment(el);
          let nthInMonth = this.getIndexInMonth(date);

          let weekday = date.day();
          let day = this.current_scedule.find((e) => {
            return e.day == weekday;
          });
          if (day && day.active) {
            if (
              (day.every_of_month && nthInMonth == day.every_of_month) ||
              (day.every_of_month_alt &&
                nthInMonth == day.every_of_month_alt) ||
              (!day.every_of_month && !day.every_of_month_alt)
            ) {
              days.push(el);
            }
          }
        });
      }
      return days;
    },
    all_departments() {
      return this.datasets.departments;
    },
    available_days_options() {
      let options = [];
      for (var i = 0; i < this.available_worker_days.length; i++) {
        let item = this.available_worker_days[i];
        options.push({
          text: this.$moment(item).format("ddd DD MMMM YYYY"),
          value: item,
        });
      }
      return options;
    },
    workersOptions() {
      let list = this.workers;
      list = list.map((e) => {
        return {
          text: e.first_name + " " + e.last_name,
          department: e.department,
          schedule: e.schedules,
        };
      });
      list = list.filter((e) => {
        return e.department;
      });
      return list;
    },
  },
  methods: {
    async getAppointmentsForSelectedDate() {
      this.isLoading = true;
      let department = this.selected_worker.department;
      let date = this.$moment(this.selected_date).toDate();
      let args = {
        collectionName: "appointments",
        where: ["date", "==", date],
        where2: ["department", "==", Number(department)],
      };
      let result = await this.$db.getCollection(args);
      console.log(result);
      if (result) {
        this.day_appointments = result;
      }
      this.isLoading = false;
    },
    async confirmAppointment() {
      this.creatingLoading = true;
      let date_time = this.$moment(
        this.selected_date + ", " + this.selected_time
      ).toDate();
      let now = new Date();
      let appointment = {
        created_by_id: this.userData.id,
        created_by: this.user.uid,
        date: this.$moment(this.selected_date).toDate(),
        time: this.selected_time,
        date_time: date_time,
        created_at: now,
        status: "active",
        canceled: false,
        note: "",
        department: this.selected_worker.department,
        worker_name: this.selected_worker.text,
      };

      //create id
      var id = await this.$api("/get/counter/?type=appointments")
        .then((response) => {
          return response.data.count;
        })
        .catch((e) => {
          console.log(e);
        });
      appointment["id"] = id;
      await this.$db.add("appointments", appointment);

      window.setTimeout(() => {
        this.creatingLoading = false;
        this.$toast.success("Запис створено");
        this.showConfirmPopup = false;
        this.$router.push({ name: "appointments" });
      }, 1000);
    },
    openConfirmPopup() {
      this.showConfirmPopup = true;
    },
    getDepartment(id) {
      let name = "";
      let result = this.all_departments.find((e) => {
        return e.id == id;
      });
      if (result) {
        name = result.name;
      }
      return name;
    },
    gelAllDays() {
      var getDaysBetweenDates = function (startDate, endDate) {
        var now = startDate.clone(),
          dates = [];

        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format("MM/DD/YYYY"));
          now.add(1, "days");
        }
        return dates;
      };

      var today = new Date();
      var tomorrow = today.setDate(today.getDate() + 1);
      var startDate = this.$moment(tomorrow);

      var afterMonth = today.setDate(today.getDate() + 30);
      var endDate = this.$moment(afterMonth);

      var dateList = getDaysBetweenDates(startDate, endDate);
      this.all_days = dateList;
    },
    getPeopleToSchedule() {
      this.isLoading = true;
      this.$api
        .get("/user/getWorkersWithSchedule/")
        .then((response) => {
          this.workers = response.data;
          if (this.$route.query.department) {
            this.setWorkerFromUrl();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    setWorkerFromUrl() {
      let selected = Number(this.$route.query.department);
      let worker = this.workersOptions.find((el) => {
        return Number(el.department) == selected;
      });
      this.selected_worker = worker;
    },
    checkIfTimeBusy(t) {
      let busy = false;
      let found = this.day_appointments.find((el) => {
        return (
          this.$moment(this.selected_date + ", " + t)
            .toDate()
            .toString() == el.date_time.toDate().toString()
        );
      });
      let start_hour = this.$moment(
        this.selected_date +
          ", " +
          this.selected_day_schedule.start_hour +
          ":00"
      ).toDate();
      let end_hour = this.$moment(
        this.selected_date + ", " + this.selected_day_schedule.end_hour + ":00"
      )
        .subtract(15, "minutes")
        .toDate();
      let selected_date_hour = this.$moment(
        this.selected_date + ", " + t
      ).toDate();

      if (selected_date_hour > end_hour || selected_date_hour < start_hour) {
        busy = true;
      }
      if (found && !found.canceled) {
        busy = true;
      }

      //Check coffee break
      let coffee_break_start = this.$moment(
        this.selected_date +
          ", " +
          this.selected_day_schedule.coffee_break.start
      ).toDate();
      let coffee_break_end = this.$moment(
        this.selected_date + ", " + this.selected_day_schedule.coffee_break.end
      ).toDate();

      if (
        selected_date_hour >= coffee_break_start &&
        selected_date_hour < coffee_break_end
      ) {
        busy = true;
      }

      return busy;
    },
    getIndexInMonth(date) {
      let index = 0;
      date = date.toDate();
      let day = date.getDay();
      let start = new Date(date.getFullYear(), date.getMonth(), 1);
      let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let current = start;
      let sameWeekdays = [];
      while (current < end) {
        if (this.$moment(current).toDate().getDay() == day) {
          sameWeekdays.push(current.toString());
        }
        current = this.$moment(current).add(1, "day").toDate();
      }

      index = sameWeekdays.indexOf(date.toString()) + 1;
      return index;
    },
    selectTime(t) {
      if (!t.busy) {
        this.selected_time = t.time;
      }
    },
  },
  mounted() {
    if (this.isUser && this.isVerified) {
      this.getPeopleToSchedule();
      this.gelAllDays();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-inner {
  .time,
  .date {
    font-size: 18px;
    font-weight: 600;
    strong {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .time {
    font-size: 28px;
  }
}
.time-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 375px) {
    grid-template-columns: 1fr 1fr;
  }
  row-gap: 16px;
  column-gap: 16px;
  margin-top: 30px;
  &__item {
    background: #dae6f9;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 32px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 4px;
    &.selected {
      background-color: var(--primary-yellow);
      transition: 0.3s;
      box-shadow: 0px 6px 16px 0px rgba(#000, 0.2);
      //   transform: scale(1.1);
    }
    &.busy {
      background-color: #e7e7e7;
      cursor: default;
      color: #666;
      &:hover {
        transform: scale(1);
      }
    }
  }
}
.info-cta {
  min-width: 100%;
  @media (min-width: 575px) {
    min-width: auto;
  }
}
</style>
