<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-3" pills>
        <b-tab title="Мої дані" :active="!$route.params.tab">
          <b-overlay
            :show="isLoading"
            variant="white"
            rounded="sm"
            spinner-variant="warning"
          >
            <div class="form-group">
              <label>Ім'я</label>
              <input
                type="text"
                class="d-block w-100"
                v-model="user.first_name"
              />
            </div>
            <div class="form-group">
              <label>Прізвище</label>
              <input
                type="text"
                class="d-block w-100"
                v-model="user.last_name"
              />
            </div>
            <div class="form-group" v-if="user.phone_number">
              <label>Номер телефону</label>
              <input
                type="text"
                class="d-block w-100"
                v-model="user.phone_number"
                disabled
              />
            </div>

            <div class="form-group">
              <b-button @click="saveChanges" variant="primary"
                >Зберегти зміни</b-button
              >
            </div>
          </b-overlay>
        </b-tab>
        <b-tab
          title="Верифікація"
          :active="$route.params.tab == 'verification'"
        >
          <myProfileVerification />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import myProfileVerification from "@/views/my/my-profile-verification.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    myProfileVerification,
  },
  data() {
    return {
      isLoading: false,
      user: {
        first_name: "",
        last_name: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    async getUserData() {
      this.isLoading = true;
      var result = await this.$db.getDocument("users", this.getUser.uid);
      this.user = result;
      this.isLoading = false;
    },
    async saveChanges() {
      this.isLoading = true;
      await this.$db.update("users", this.getUser.uid, {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
      });

      this.$toast.success("Зміни збережено");

      this.isLoading = false;
    },
    getUserDataRemote() {
      this.isLoading = true;
      var uid = this.getUser.uid;
      fetch("https://project.realno.top/api/user/get/", {
        method: "POST", // или 'PUT'
        // mode: "no-cors",
        body: JSON.stringify({ uid: uid }),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);

          //Зараз
          this.user.first_name = data.data.first_name;
          this.user.last_name = data.data.last_name;

          this.isLoading = false;
        });
    },
    saveChangesRemote() {
      this.isLoading = true;
      var uid = this.getUser.uid;
      var user = { ...this.user };
      user["uid"] = uid;
      user["auto_create"] = "on";

      fetch("https://project.realno.top/api/user/set/", {
        method: "POST", // или 'PUT'
        body: JSON.stringify(user),
        // mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          this.isLoading = false;
          this.$toast.success("Зміни збережено");
        });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style lang="scss" scoped></style>
