<template>
  <b-card style="margin-bottom: 20px" :class="{ canceled: a.canceled }">
    <b-badge class="badge-success" v-if="!a.canceled && !past_event">
      Активно
    </b-badge>
    <b-badge class="badge-canceled" v-if="a.canceled"> Скасовано </b-badge>
    <p class="mb-1 mt-2">На коли:</p>
    <div class="date_time">
      <div class="date d-flex justify-content-between">
        <span>
          {{ $moment(a.date_time.toDate()).format("dddd, DD MMMM YYYY") }}
        </span>
      </div>
      <div class="time mb-4">
        {{ $moment(a.date_time.toDate()).format("HH:mm") }}
      </div>
    </div>
    <div class="to">
      <p class="mb-1">Запис до:</p>
      <div class="name pt-2">
        <strong>
          {{ a.worker_name }}
        </strong>
      </div>
      <div class="department">
        {{ department.name }}
      </div>
    </div>
    <b-alert
      variant="warning"
      class="note mt-2"
      show
      v-if="a.note && a.note.length"
    >
      {{ a.note }}
    </b-alert>
    <div class="actions mt-4">
      <b-button
        :disabled="a.canceled"
        @click="showCancelPopup = true"
        :variant="
          !a.canceled && !past_event ? 'outline-danger' : 'outline-secondary'
        "
        class="w-100"
      >
        <span v-if="a.canceled"> Запис скасовано </span>
        <span v-else> Скасувати запис </span>
      </b-button>
    </div>
    <b-modal centered hide-footer hide-header v-model="showCancelPopup">
      <b-overlay
        :show="isLoading"
        :class="{ 'overlay-loading': isLoading }"
        variant="white"
        rounded="sm"
        spinner-variant="warning"
      >
        <h4>Ви справді хочете скасувати запис до</h4>
        <div class="to py-4">
          <div class="name">
            <strong>
              {{ a.worker_name }}
            </strong>
          </div>
          <p class="department">
            {{ department.name }}
          </p>
          на
          <strong>{{
            $moment(a.date_time.toDate()).format("dddd, DD MMMM YYYY HH:mm")
          }}</strong>
        </div>
        <div class="cta mt-2">
          <b-button
            @click="cancelAppointment"
            variant="danger"
            class="w-100 mb-2"
          >
            Так, скасувати запис
          </b-button>
          <b-button
            @click="showCancelPopup = false"
            variant="outline-primary"
            class="w-100"
          >
            Hi, залишити запис
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["a"],
  data() {
    return {
      isLoading: false,
      showCancelPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userData: "getUserData",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),
    past_event() {
      let past = false;
      let today = new Date();
      let event_date = this.a.date_time.toDate();
      if (today > event_date) {
        past = true;
      }
      return past;
    },
    all_departments() {
      return this.datasets.departments;
    },
    department() {
      let department = "--";
      let found = this.all_departments.find((el) => {
        return el.id == this.a.department;
      });
      if (found) {
        department = found;
      }
      return department;
    },
  },
  methods: {
    cancelAppointment() {
      this.isLoading = true;
      let result = this.$db.update("appointments", this.a.uid, {
        canceled: true,
      });
      window.setTimeout(() => {
        if (result) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
        this.showCancelPopup = false;
        this.$toast.success("Запис успішно скасовано");
        this.$emit("update");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.canceled {
  *:not(button) {
    color: #999;
  }
}
.icon {
  width: 64px !important;
  height: 64px !important;
}
.time {
  font-size: 32px;
  font-weight: 600;
}
.date {
  color: #000000;
  font-weight: 500;
}
.actions {
  .btn {
    height: 42px;
    font-size: 16px;
  }
}
.name {
  font-size: 24px;
}
.badge-success {
  background-color: #21b921;
  color: #fff;
}
.badge-canceled {
  background-color: #999;
  color: #fff !important;
}
.note {
  background: #ffefbd;
  color: #786116 !important;
  box-shadow: none;
  padding: 6px 8px;
  border-radius: 6px;
}
</style>
