<template>
  <div>
    <h1>
      Мій профіль
    </h1>
    <p>
      Тут можна редагувати Ваші дані, провести верифікацію профілю, тощо
    </p>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
