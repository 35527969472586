import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import store from "../store";
function authService(app) {
  const auth = getAuth(app);
  return {
    logOut: async () => {
      var result = await signOut(auth)
        .then(() => {
          result = true;
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
          result = false;
        });
      return result;
    },
    createUserWithEmail: async (email, password) => {
      var result = await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          store.commit("setUser", user);
          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });
      return result;
    },

    signInWithEmail: async (email, password) => {
      var result = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          store.commit("setUser", user);
          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });

      return result;
    },
    passwordReset: async (email, url) => {
      var result = false;
      try {
        await sendPasswordResetEmail(auth, email, url, () => {
          result = true;
        });
      } catch (e) {
        result = e.message;
      }
      return result;
    },
    newPasswordConfirm: async (code, new_password) => {
      var result = await confirmPasswordReset(auth, code, new_password)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
      return result;
    },
    onUserAuthChange: async () => {
      var result = await onAuthStateChanged(auth, (user) => {
        if (user) {
          store.commit("setUser", user);
          localStorage.setItem("token", user.accessToken);
        } else {
          store.dispatch("clearData");
          localStorage.removeItem("token");
        }
      });
      return result;
    },
  };
}

export { authService as default };
