<template>
  <div>
    <b-alert show variant="warning">
      Для того аби скористатися сервісом потібрно
      <router-link
        :to="{ name: 'my-profile', params: { tab: 'verification' } }"
      >
        верифікувати аккаунт</router-link
      >
    </b-alert>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
