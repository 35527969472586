<template>
  <div class="home">
    <b-card style="height:100vh"> </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters({
      user: "getUser",
      isUser: "isUser",
    }),
  },
  watch: {
    user: {
      handler() {
        if (this.user) {
          this.$router.push({ name: "services" });
        } else {
          this.$router.push("/auth/login");
        }
      },
    },
  },
  beforeMount() {
    if (this.isUser) {
      this.$router.push({ name: "services" });
    } else {
      this.$router.push("/auth/login");
    }
  },
};
</script>
