<template>
  <div>
    <h1>Мої записи на прийом</h1>
    <p>
      Тут відображатимуться Ваші записи на прийом до посадових осіб. <br />Ви
      можете створити запис, скориставшись послугою

      <router-link
        :to="{
          name: 'service-single-local',
          params: { slug: 'schedule-an-appointment' },
        }"
        >Запис на прийом до посадовця</router-link
      >
    </p>
    <b-overlay
      :show="isLoading"
      :class="{ 'overlay-loading': isLoading }"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <div class="row">
        <div
          class="col-12 col-md-6"
          v-for="a in sortedAppointments"
          :key="a.date_time.seconds"
        >
          <appointmentItem :a="a" @update="getAppointments" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import appointmentItem from "@/components/appointment-list-item.vue";
import _ from "lodash";
export default {
  components: {
    appointmentItem,
  },
  data() {
    return {
      isLoading: false,
      appointments: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userData: "getUserData",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),
    sortedAppointments() {
      return _.orderBy(this.appointments, "date_time", "asc");
    },
  },
  methods: {
    async getAppointments() {
      this.isLoading = true;
      let args = {
        collectionName: "appointments",
        where: ["created_by", "==", this.user.uid],
      };
      let result = await this.$db.getCollection(args);
      if (result) {
        this.appointments = result;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getAppointments();
  },
};
</script>

<style lang="scss" scoped></style>
