<template>
  <div class="user">
    <template v-if="!isUser">
      <b-button variant="primary auth-btn" @click="openLoginModal" class="login"
        >Вхід</b-button
      >
      <b-button
        variant="outline-primary auth-btn"
        @click="openRegisterModal"
        class="registration"
        >Реєстрація</b-button
      >
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <div class="text d-flex align-items-center" @click="toggleDropdown">
            <div class="name d-none d-sm-block">
              <strong>{{ displayName }}</strong>
            </div>
            <b-avatar variant="info" :text="avatarLetters"> </b-avatar>
          </div>
        </div>

        <b-dropdown
          ref="dropdown"
          :class="{ show: showDropDown }"
          id="user-dropdown"
          variant="link"
          right
        >
          <b-dropdown-item
            :to="{ name: 'my-profile', params: { tab: 'general' } }"
            >Мій профіль</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="signOut">Вийти</b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showDropDown: false,
      user: true,
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUser", "getUserData"]),
    avatarLetters() {
      var name = "АБ";
      if (this.getUser) {
        name = this.getUser.email[0] + this.getUser.email[1];
      }
      if (
        this.getUserData &&
        this.getUserData.first_name &&
        this.getUserData.last_name
      ) {
        name = this.getUserData.first_name[0] + this.getUserData.last_name[0];
      }
      return name;
    },
    displayName() {
      var name = "Гість";
      if (this.getUser) {
        name = this.getUser.email;
      }
      if (
        this.getUserData &&
        this.getUserData.first_name &&
        this.getUserData.last_name
      ) {
        name = this.getUserData.first_name + " " + this.getUserData.last_name;
      }
      return name;
    },
  },
  methods: {
    toggleDropdown() {
      this.$refs.dropdown.show(true);
    },
    async signOut() {
      var result = await this.$auth.logOut();
      if (result == true) {
        this.$toast.warning("Ви вийшли із кабінету");
      }
    },
    openLoginModal() {
      this.$bvModal.show("auth-modal");
    },
    openRegisterModal() {
      this.$bvModal.show("auth-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.registration {
  margin-left: 10px;
}
.login {
  min-width: 120px;
}
.user {
  #user-dropdown {
    button {
      padding: 6px 0px!important;
    }
  }
  .text {
  cursor: pointer;
  }
  .name {
    color: #104d82;
    margin-right: 8px;
  
  }
  .auth-btn {
    height: 36px;
    line-height: 1;
    font-size: 15px;
  }
}
</style>
