import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Auth from "../views/Auth.vue";
import ForgotPassword from "../views/Forgot-Password.vue";
import ForgotPasswordConfirm from "../views/Forgot-Password-Confirm.vue";
import Services from "../views/Services.vue";
import ServiceSingle from "../views/Service-single.vue";
import My from "../views/My.vue";
import myProfile from "../views/my/my-profile.vue";
import myTickets from "../views/Tickets.vue";
import myAppointments from "../views/Appointments.vue";
import myTicketsAll from "../views/tickets/all.vue";
import myTicketSingle from "../views/tickets/single-ticket.vue";
import Notifications from "../views/Notifications.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Ласкаво просимо", layout: "sidebar", requiresAuth: false },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { title: "Сповіщення", layout: "sidebar", requiresAuth: true },
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    meta: { title: "Каталог сервісів", layout: "sidebar", requiresAuth: false },
  },
  {
    path: "/services/external/:id",
    name: "service-single-external",
    component: ServiceSingle,
    props: true,
    meta: { title: "Сервіс", layout: "sidebar", requiresAuth: false },
  },
  {
    path: "/services/local/:slug",
    name: "service-single-local",
    component: ServiceSingle,
    props: true,
    meta: { title: "Сервіс", layout: "sidebar", requiresAuth: false },
  },
  {
    path: "/my",
    redirect: "/my/profile/general",
    name: "my",
    component: My,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/my/profile/:tab",
        name: "my-profile",
        component: myProfile,
        meta: { title: "Мій профіль", layout: "sidebar", requiresAuth: true },
      },
    ],
  },
  {
    path:'/appointments',
    component:myAppointments,
    name:'appointments',
    meta: {  title: "Записи на прийом", layout: "sidebar",requiresAuth: true },
  },
  {
    path: "/tickets",
    name: "tickets",
    redirect: "/tickets/all",
    component: myTickets,
    meta: { title: "Мої звернення", layout: "sidebar", requiresAuth: true },
    children: [
      {
        path: "all",
        name: "tickets-all",
        component: myTicketsAll,
        meta: { title: "Мої звернення", layout: "sidebar", requiresAuth: true },
      },
      {
        path: "t/:id",
        name: "single-ticket",
        component: myTicketSingle,
        meta: { title: "Мої звернення", layout: "sidebar", requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth/:case",
    name: "Auth",
    component: Auth,
    meta: { layout: "auth", requiresAuth: false },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { layout: "auth", requiresAuth: false },
  },
  {
    path: "/forgot-password/confirm",
    name: "forgot-password-confirm",
    component: ForgotPasswordConfirm,
    meta: { layout: "auth", requiresAuth: false },
  },
  {
    path:'*',
    redirect:'/'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && store.state.user == null) {
    next("/services");
  }

  next();
});

export default router;
