import api from "@/providers/api";
const actions = {
  clearData(context) {
    context.commit("setUserData", {});
    context.commit("setUser", null);
  },
  toggleSidebar(context, payload) {
    context.commit("toggleSidebar", payload);
  },
  setUserData(context, payload) {
    context.commit("setUserData", payload);
  },
  setNotificationsCount(context, payload) {
    context.commit("setNotificationsCount", payload);
  },
  getDatasets(context) {
    api.axiosApi
      .get("/get/userDatasets/")
      .then((response) => {
        context.commit("setDatasets", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default actions;
