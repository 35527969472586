<template>
  <div>
    <div class="service__head d-flex flex-column flex-sm-row align-items-baseline align-items-sm-center">
      <div
        class="service__thumb"
        :style="{ backgroundImage: 'url(' + service.acf.image + ')' }"
      ></div>
      <div class="d-flex flex-column">
        <h1 class="service__title">
          {{ service.title.rendered }}
        </h1>
        <p class="service__descr">{{ service.acf.service_descr }}</p>
      </div>
    </div>
    <div class="service__body">
      <b-tabs content-class="mt-3" class="mt-4" pills>
        <b-tab title="Сервіс" active>
            <div v-if="service.acf.external_link" class="service__external-link">
                <b-button variant="primary" :href="service.acf.service_url" target="_blank">
                    Перейти до сервісу
                </b-button>
            </div>
            <div v-else>
                <div v-html="service.content.rendered"></div>
            </div>
        </b-tab>
        <b-tab title="Інформація">
            <div v-html="service.acf.info"></div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
  service_tabs: ["info", "faq", "howto"],
};
</script>

<style lang="scss" scoped>
.service {
  &__external-link {
    padding: 12px;
    background: #f4f4f4;
    border-radius: 8px;
  }
  &__title {
    font-size: 32px;
    @media (max-width: 575px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  &__descr {
    font-size: 15px;
    color: #424242;
  }
  &__thumb {
    width: 180px;
    min-width: 180px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
    margin-right: 30px;
    background-color: #e7e7e7;
    border: 2px solid #fff;
  }
}
</style>
