<template>
  <div>
    <b-alert show variant="warning">
      Для того аби скористатися сервісом потібрно
      <a @click.prevent="openAuthModal" href="#"> Увійти </a>і верифікувати
      аккаунт
    </b-alert>
  </div>
</template>

<script>
export default {
  methods: {
    openAuthModal() {
      this.$bvModal.show("auth-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
