<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <template v-if="!is_sent">
        <departmentSelect
          :department="s.default_department"
          @select="selectDepartment"
        />
        <div class="form-group">
          <label class="mb-3 mt-4">
            Уточніть Ваш запит <span class="required">*</span>
          </label>
          <b-form-textarea
            placeholder="Уточніть запит, аби отримати максимально точну відповідь"
            rows="8"
            v-model="ticket.text"
          />
          <div class="text-left">
            <textLength :minimum="60" :text="ticket.text" :limit="text_limit" />
          </div>
          <div class="form-grou" v-if="isVerified && isUser">
            <fileUploader @update="addFiles" />
          </div>
          <div class="form-group mt-2 d-flex justify-content-end">
            <b-button
              v-if="isVerified && isUser"
              @click="sendTicket"
              :disabled="buttonDisabled"
              variant="primary"
              class="main-cta"
            >
              Надіслати
            </b-button>
            <needsVerirication class="w-100" v-if="isUser && !isVerified" />
            <needsAuth class="w-100" v-if="!isUser" />
          </div>
        </div>
      </template>
      <div v-else>
        <request-sent />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import textLength from "@/components/text-length.vue";
import requestSent from "@/components/request-sent.vue";
import needsVerirication from "@/components/need-verification-in-service.vue";
import needsAuth from "@/components/need-auth-in-service.vue";
import departmentSelect from "@/components/local-services/sub-components/departmentSelect.vue";
import fileUploader from "@/components/file-uploader.vue";
export default {
  components: {
    requestSent,
    textLength,
    needsVerirication,
    needsAuth,
    fileUploader,
    departmentSelect,
  },
  props: ["s"],
  data() {
    return {
      files: [],
      text_limit: 1000,
      isLoading: false,
      is_sent: false,
      ticket: {
        text: "",
        type: "information_request",
        id: null,
        sender: null,
        department: null,
        created_at: null,
        status: 0,
        files: [],
      },
    };
  },
  computed: {
    buttonDisabled() {
      var disabled = false;
      if (!this.ticket.department) {
        disabled = true;
      }
      if (!this.ticket.text || this.ticket.text.length < 20) {
        disabled = true;
      }
      return disabled;
    },
    ...mapGetters({
      user: "getUserData",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),
  },
  methods: {
    selectDepartment(value) {
      this.ticket.department = value;
    },
    addFiles(data) {
      this.ticket.files = data;
    },
    async sendTicket() {
      this.isLoading = true;
      this.ticket.created_at = new Date();

      this.ticket.sender = this.user.uid;
      //create id
      var id = await this.$api("/get/counter/?type=tickets")
        .then((response) => {
          return response.data.count;
        })
        .catch((e) => {
          console.log(e);
        });
      this.ticket.id = id;
      let data = this.ticket;
      data["sender_info"] = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        full_name: this.user.last_name + " " + this.user.first_name,
        id: this.user.id,
      };
      await this.$db.add("tickets", data);
      this.is_sent = true;
      this.isLoading = false;
    },
  },
  mounted() {
    if (this.s && this.s.default_department) {
      this.ticket.department = this.s.default_department;
    }
  },
};
</script>

<style lang="scss">
h2 {
  svg {
    height: 64px !important;
    width: 64px !important;
    margin-bottom: 20px;
  }
}
.form-group {
  label {
    font-size: 20px;
  }
}
.main-cta {
  min-width: 240px;
}
</style>
