<template>
  <div>
    <b-modal id="auth-modal" hide-footer hide-header>
      <div class="pb-4">
        <logo :dark="true" class="mb-4 mt-4" />
      </div>
      <auth-form />
    </b-modal>
  </div>
</template>

<script>
import Logo from "../../layout/logo.vue";
import authForm from "../auth-form/auth-form.vue";
export default {
  components: { authForm, Logo },
};
</script>

<style lang="scss" scoped></style>
