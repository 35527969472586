const mutations = {
  setUserData(state, payload) {
    state.userData = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setNotificationsCount(state, payload) {
    state.notificationsCount = payload;
  },
  setDatasets(state, payload) {
    state.datasets = payload;
  },
  toggleSidebar(state, payload){
    state.sideBarOpened = payload
  }
};

export default mutations;
