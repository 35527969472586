<template>
  <b-card class="mb-3">
    <div class="n__header d-flex align-items-center mb-2">
      <div class="n__status-change" v-if="n.next_status">
        Зміна статусу:
        <statusBadge :status="n.prev_status" />
        <icon style="margin: 0 10px" variant="arrow-right" />
        <statusBadge class="mr-1" :status="n.next_status" />
        <span v-if="n.next_department">
          до
          <strong>
            {{ getDepartment(n.next_department).name }}
          </strong>
        </span>
      </div>
    </div>
    <div class="n__type">
      для
      <router-link
        :to="{ name: 'single-ticket', hash: n.id, params: { id: n.ticket } }"
      >
        {{ getType(n.ticket_type) }} #{{ n.ticket }}
      </router-link>
    </div>
    <div
      class="
        n__footer
        flex-column flex-sm-row
        text-start text-sm-right
        align-items-baseline
        d-flex
        align-items-sm-center
      "
    >
      <div class="n__date">
        {{ n.created_at.seconds | moment("calendar") }}
      </div>
      <div class="n__actions ml-auto">
        <a href="#" @click.prevent="markAsRead(n.id)">
          Відмітити як прочитане
        </a>
        <router-link
          :to="{ name: 'single-ticket', hash: n.id, params: { id: n.ticket } }"
        >
          Проглянути
        </router-link>
      </div>
    </div>
  </b-card>
</template>

<script>
import statusBadge from "@/components/ticket-status-badge.vue";
import { mapGetters } from "vuex";
export default {
  props: ["n"],
  components: {
    statusBadge,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
  },
  methods: {
    async markAsRead(id) {
      console.log(id);
      await this.$db.update("tickets_events", this.n.uid, { seen: true });
      this.$emit("update");
    },
    getType(slug) {
      var type = slug;
      if (this.ticket_types) {
        var found = this.ticket_types.find((el) => el.slug.trim() == slug);
        if (found) {
          type = found.name;
        }
      }

      return type;
    },
    getDepartment(id) {
      var department = this.departments.find((el) => el.id == id);
      return department;
    },
  },
};
</script>

<style lang="scss" scoped>
.n {
  &__header {
    font-size: 13px;
    color: #666;
    strong {
      color: #424242;
    }
    .status-badge {
      padding: 4px 6px;
      border-radius: 3px;
    }
  }
  &__type {
    padding-top: 5px;
    font-size: 18px;
    a {
      text-decoration: none;
    }
  }
  &__actions {
    @media (max-width: 575px) {
      margin-left: 0 !important;
      text-align: left !important;
      margin-top: 30px;
      a {
        margin: 0 !important;
        margin-right: 20px !important;
      }
    }
  }
  &__footer {
    text-align: right;
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 13px;
    a {
      margin-left: 12px;
      font-size: 13px;
    }
  }
}
</style>
