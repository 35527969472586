<template>
  <div class="text-center pb-4">
    <h2
      class="text-center py-4 mt-4 mb-0 d-flex flex-column align-items-center"
    >
      <icon variant="checkmark-rounded" fill="green" :height="48" :width="48" />
      Дякуємо. Повідомлення було надіслано
    </h2>
    <p class="text-center">
      Перевірити статус можна в розділі "Мої запити і звернення"
    </p>
    <b-button :to="{ name: 'tickets-all' }" variant="primary">
      Мої запити і звернення
    </b-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
