<template>
  <span
    class="my-1 d-inline-block"
    :class="{ error: Number(limit) < text.length }"
  >
    {{ text.length }} / {{ limit }}
    <span class="min" v-if="minimum"> щонайменше {{ minimum }}</span>
  </span>
</template>

<script>
export default {
  props: ["limit", "text", "minimum"],
};
</script>

<style lang="scss" scoped>
.error {
  color: #f00;
}
.min {
  color: #666;
  font-size: 13px;
}
</style>
