<template>
  <div style="display: none !important">
    <svg id="icon-grid" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41667 0H1.08333C0.485333 0 0 0.485333 0 1.08333V5.41667C0 6.01467 0.485333 6.5 1.08333 6.5H5.41667C6.01467 6.5 6.5 6.01467 6.5 5.41667V1.08333C6.5 0.485333 6.01467 0 5.41667 0Z"
        fill="white"
      />
      <path
        d="M5.41667 9.75H1.08333C0.485333 9.75 0 10.2353 0 10.8333V15.1667C0 15.7647 0.485333 16.25 1.08333 16.25H5.41667C6.01467 16.25 6.5 15.7647 6.5 15.1667V10.8333C6.5 10.2353 6.01467 9.75 5.41667 9.75Z"
        fill="white"
      />
      <path
        d="M5.41667 19.5H1.08333C0.485333 19.5 0 19.9853 0 20.5833V24.9167C0 25.5147 0.485333 26 1.08333 26H5.41667C6.01467 26 6.5 25.5147 6.5 24.9167V20.5833C6.5 19.9853 6.01467 19.5 5.41667 19.5Z"
        fill="white"
      />
      <path
        d="M15.1667 0H10.8333C10.2353 0 9.75 0.485333 9.75 1.08333V5.41667C9.75 6.01467 10.2353 6.5 10.8333 6.5H15.1667C15.7647 6.5 16.25 6.01467 16.25 5.41667V1.08333C16.25 0.485333 15.7647 0 15.1667 0Z"
        fill="white"
      />
      <path
        d="M15.1667 9.75H10.8333C10.2353 9.75 9.75 10.2353 9.75 10.8333V15.1667C9.75 15.7647 10.2353 16.25 10.8333 16.25H15.1667C15.7647 16.25 16.25 15.7647 16.25 15.1667V10.8333C16.25 10.2353 15.7647 9.75 15.1667 9.75Z"
        fill="white"
      />
      <path
        d="M15.1667 19.5H10.8333C10.2353 19.5 9.75 19.9853 9.75 20.5833V24.9167C9.75 25.5147 10.2353 26 10.8333 26H15.1667C15.7647 26 16.25 25.5147 16.25 24.9167V20.5833C16.25 19.9853 15.7647 19.5 15.1667 19.5Z"
        fill="white"
      />
      <path
        d="M24.9167 0H20.5833C19.9853 0 19.5 0.485333 19.5 1.08333V5.41667C19.5 6.01467 19.9853 6.5 20.5833 6.5H24.9167C25.5147 6.5 26 6.01467 26 5.41667V1.08333C26 0.485333 25.5147 0 24.9167 0Z"
        fill="white"
      />
      <path
        d="M24.9167 9.75H20.5833C19.9853 9.75 19.5 10.2353 19.5 10.8333V15.1667C19.5 15.7647 19.9853 16.25 20.5833 16.25H24.9167C25.5147 16.25 26 15.7647 26 15.1667V10.8333C26 10.2353 25.5147 9.75 24.9167 9.75Z"
        fill="white"
      />
      <path
        d="M24.9167 19.5H20.5833C19.9853 19.5 19.5 19.9853 19.5 20.5833V24.9167C19.5 25.5147 19.9853 26 20.5833 26H24.9167C25.5147 26 26 25.5147 26 24.9167V20.5833C26 19.9853 25.5147 19.5 24.9167 19.5Z"
        fill="white"
      />
    </svg>
    <svg id="icon-bell" viewBox="0 0 23 27" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7411 21.4868C21.6618 21.4353 21.0644 21.0168 20.4663 19.7509C19.3679 17.4263 19.1374 14.1516 19.1374 11.8138C19.1374 11.8036 19.1372 11.7936 19.1368 11.7834C19.1248 8.69379 17.2747 6.03039 14.6273 4.83537V3.00776C14.6273 1.34926 13.2802 0 11.6245 0H11.3756C9.71985 0 8.3728 1.34926 8.3728 3.00776V4.83527C5.71647 6.03413 3.86269 8.71114 3.86269 11.8138C3.86269 14.1516 3.63214 17.4262 2.53373 19.7509C1.93567 21.0168 1.33835 21.4352 1.25893 21.4868C0.924492 21.6416 0.759592 21.9991 0.836531 22.3614C0.914209 22.7274 1.25624 22.9796 1.62976 22.9796H7.42775C7.46013 25.2019 9.27387 27 11.5001 27C13.7262 27 15.54 25.2019 15.5724 22.9796H21.3703C21.7438 22.9796 22.0859 22.7274 22.1636 22.3614C22.2404 21.9991 22.0756 21.6415 21.7411 21.4868ZM9.95489 3.00776C9.95489 2.22302 10.5922 1.58462 11.3757 1.58462H11.6245C12.4079 1.58462 13.0453 2.22302 13.0453 3.00776V4.32174C12.546 4.21854 12.0291 4.16422 11.4998 4.16422C10.9707 4.16422 10.454 4.21849 9.95494 4.32158V3.00776H9.95489ZM11.5001 25.4154C10.1462 25.4154 9.04189 24.3282 9.00978 22.9797H13.9903C13.9582 24.3281 12.8539 25.4154 11.5001 25.4154ZM14.6852 21.395C14.6851 21.395 3.43249 21.395 3.43249 21.395C3.56933 21.1817 3.7086 20.9402 3.84703 20.6673C4.9072 18.5768 5.44478 15.598 5.44478 11.8138C5.44478 8.46956 8.16107 5.74884 11.4998 5.74884C14.8385 5.74884 17.5548 8.46956 17.5548 11.8165C17.5548 11.8262 17.555 11.8359 17.5553 11.8456C17.5583 15.6146 18.0959 18.5827 19.1531 20.6673C19.2915 20.9403 19.4308 21.1817 19.5676 21.395H14.6852Z"
        fill="white"
      />
    </svg>

    <svg
      version="1.1"
      id="icon-user"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
			C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
			c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
			h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
			c59.551,0,108,48.448,108,108S315.551,256,256,256z"
          />
        </g>
      </g>
    </svg>

    <svg
      id="icon-papers"
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M20.6377 3.73333V0H6.25629L2.62891 3.62744V23.2667H6.36224V27H24.3711V3.73333H20.6377ZM6.78089 1.71276V4.15199H4.34167L6.78089 1.71276ZM4.21094 5.73402H8.36293V1.58203H19.0557V21.6846H4.21094V5.73402ZM22.789 25.418H7.94427V23.2667H20.6377V5.31536H22.789V25.418Z"
          fill="white"
        />
        <path
          d="M6.4873 7.31604H16.7793V8.89807H6.4873V7.31604Z"
          fill="white"
        />
        <path
          d="M6.4873 10.4801H16.7793V12.0621H6.4873V10.4801Z"
          fill="white"
        />
        <path
          d="M6.4873 13.6442H16.7793V15.2262H6.4873V13.6442Z"
          fill="white"
        />
      </g>
    </svg>

    <svg
      version="1.1"
      id="icon-checkmark-rounded"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z M225.019,372.44L112.914,260.336
			l42.422-42.422l71.646,71.646l143.833-130.752l40.371,44.385L225.019,372.44z"
          />
        </g>
      </g>
    </svg>
    <svg
      id="icon-arrow-right"
      enable-background="new 0 0 1560 1560"
      viewBox="0 0 1560 1560"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                d="m1524 811.8h-1488c-17.7 0-32-14.3-32-32s14.3-32 32-32h1410.7l-194.2-194.2c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l248.9 248.9c9.2 9.2 11.9 22.9 6.9 34.9-5 11.9-16.7 19.7-29.6 19.7z"
              />
            </g>
            <g>
              <path
                d="m1274.8 1061c-8.2 0-16.4-3.1-22.6-9.4-12.5-12.5-12.5-32.8 0-45.3l249.2-249.2c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-249.2 249.2c-6.3 6.3-14.5 9.4-22.7 9.4z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      version="1.1"
      id="icon-menu"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 341.333 341.333"
      style="enable-background: new 0 0 341.333 341.333"
      xml:space="preserve"
    >
      <g>
        <g>
          <rect y="277.333" width="341.333" height="42.667" />
        </g>
      </g>
      <g>
        <g>
          <rect y="149.333" width="341.333" height="42.667" />
        </g>
      </g>
      <g>
        <g>
          <rect y="21.333" width="341.333" height="42.667" />
        </g>
      </g>
    </svg>
    <svg
      version="1.1"
      id="icon-calendar"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <circle cx="386" cy="210" r="20" />
            <path
              d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
				c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
				c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
				c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
				v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
				c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z"
            />
            <path
              d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
				c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z"
            />
            <path
              d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
				c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z"
            />
            <circle cx="299" cy="210" r="20" />
            <circle cx="212" cy="297" r="20" />
            <circle cx="125" cy="210" r="20" />
            <circle cx="125" cy="297" r="20" />
            <circle cx="125" cy="384" r="20" />
            <circle cx="212" cy="384" r="20" />
            <circle cx="212" cy="210" r="20" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      id="icon-warning"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m56.463 14.337-49.563 92.307c-2.8 5.217 1.273 11.356 7.537 11.356h99.126c6.264 0 10.338-6.139 7.537-11.356l-49.563-92.307c-3.106-5.783-11.968-5.783-15.074 0z"
          fill="#fad271"
        />
        <g fill="#423e4f">
          <path
            d="m64 31.726a5.418 5.418 0 0 0 -5.5 5.45l1.017 44.289a4.422 4.422 0 0 0 4.483 4.261 4.422 4.422 0 0 0 4.482-4.261l1.018-44.289a5.418 5.418 0 0 0 -5.5-5.45z"
          />
          <circle cx="64" cy="100.222" r="6" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
