<template>
  <div v-if="$route.meta.layout != 'auth'">
    <template>
      <template v-if="isUser">
        <b-alert :show="!isVerified" variant="warning">
          Для того аби отримати повний доступ до усіх послуг, потрібно
          <router-link
            :to="{ name: 'my-profile', params: { tab: 'verification' } }"
          >
            верифікувати аккаунт</router-link
          >
        </b-alert>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isUser: "isUser",
      user: "getUser",
      isVerified: "isPhoneVerified",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
