<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <div class="form-group" v-if="!is_sent">
        <label class="mb-3 mt-4">
          Текст анонімного звернення <span class="required">*</span>
        </label>
        <b-form-textarea
          placeholder="Уточніть де і за яких обставин Вам довелось зіткнутись з корупцією"
          rows="8"
          v-model="ticket.text"
        />
        <textLength
          :minimum="60"
          :limit="this.max_length"
          :text="ticket.text"
        />
        <b-alert show variant="primary" class="mt-2">
          Це повідомлення є абсолютно анонімним і ніяк не дає можливості
          ідентифікувати відправника
        </b-alert>
        <div class="form-group mt-2 d-flex justify-content-end">
          <b-button
            :disabled="buttonDisabled"
            @click="sendTicket"
            variant="primary"
            class="main-cta"
          >
            Надіслати
          </b-button>
        </div>
      </div>
      <div v-else>
        <h2 class="text-center py-4 my-4 d-flex flex-column align-items-center">
          <icon
            variant="checkmark-rounded"
            fill="green"
            :height="48"
            :width="48"
          />
          Дякуємо. Повідомлення було надіслано
        </h2>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import textLength from "@/components/text-length.vue";
export default {
  props: ["s"],
  components: {
    textLength,
  },
  data() {
    return {
      max_length: 1000,
      isLoading: false,
      is_sent: false,
      ticket: {
        text: "",
        type: "curruption_report",
        id: null,
        sender: null,
        department: null,
        created_at: null,
        status: 0,
      },
    };
  },
  computed: {
    hasDepartment() {
      let has = false;

      if (this.s && this.s.default_department !== null) {
        has = true;
      }
      return has;
    },
    buttonDisabled() {
      var disabled = false;

      if (
        this.ticket.text.length < 60 ||
        this.ticket.text.length > this.max_length
      ) {
        disabled = true;
      }

      return disabled;
    },
  },
  methods: {
    async sendTicket() {
      this.isLoading = true;
      this.ticket.created_at = new Date();
      //create id
      var id = await this.$api("/get/counter/?type=tickets")
        .then((response) => {
          return response.data.count;
        })
        .catch((e) => {
          console.log(e);
        });
      this.ticket.id = id;
      await this.$db.add("tickets", this.ticket);
      this.is_sent = true;
      this.isLoading = false;
    },
  },
  mounted() {
    if (this.s && this.s.default_department) {
      this.ticket.department = this.s.default_department[0];
    }
  },
};
</script>

<style lang="scss" scoped>
h2 {
  svg {
    height: 64px !important;
    width: 64px !important;
    margin-bottom: 20px;
  }
}
.form-group {
  label {
    font-size: 20px;
  }
}
.main-cta {
  min-width: 240px;
}
</style>
