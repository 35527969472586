import Vue from "vue";
import Vuex from "vuex";

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "citizen",
});

Vue.use(Vuex);

const initialState = () => {
  return {
    user: null,
    userData: {},
    error: null,
    datasets: {},
    sideBarOpen:false,
    notificationCount:0,
  };
};

export default new Vuex.Store({
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
  plugins: [vuexLocal.plugin],
});
